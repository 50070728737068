import React, { Component } from 'react';
import '../styles/Navbar.scss';
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks,
} from 'body-scroll-lock';

export default class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false,
		};
	}

	toggleMenu = () => {
		this.setState((state) => {
			if (state.menuOpen) {
				enableBodyScroll(this.targetElement);
			} else {
				disableBodyScroll(this.targetElement);
			}
			return { menuOpen: !state.menuOpen };
		});
	};

	menuClose = () => {
		enableBodyScroll(this.targetElement);
		this.setState({ menuOpen: false });
	};

	componentDidMount() {
		this.targetElement = document.getElementById('menu-overlay');
	}

	componentWillUnmount = () => {
		clearAllBodyScrollLocks();
	};

	render() {
		return (
			<React.Fragment>
				<div
					className={`menu-overlay ${this.state.menuOpen ? '' : 'is-hidden'}`}
					id="menu-overlay"
				>
					<ul className="mobile-nav">
						<li>
							<a href="/#new-y" onClick={this.menuClose}>
								A New Barrie Y
							</a>
						</li>
						<li>
							<a href="/#reason" onClick={this.menuClose}>
								What's Your Reason
							</a>
						</li>
						<li>
							<a href="/#details" onClick={this.menuClose}>
								Project Details
							</a>
						</li>
						<li>
							<a href="/donate" onClick={this.menuClose}>
								Donate
							</a>
						</li>
					</ul>
					<div className="social-icons">
						<a href="https://twitter.com/YMCASM">
							<img
								className="social-icon"
								src="/img/Social_Twitter.svg"
								alt="Twitter Logo"
							/>
						</a>
						<a href="https://www.instagram.com/ymcasm/">
							<img
								className="social-icon"
								src="/img/Social_Insta.svg"
								alt="Instagram Logo"
							/>
						</a>
						<a href="https://www.facebook.com/YMCAofSimcoeMuskoka/">
							<img
								className="social-icon"
								src="/img/Social_Facebook-01.svg"
								alt="Facebook Logo"
							/>
						</a>
						<div className="details">
							<h2>Share your reason using</h2>
							<h2 className="big">#100ReasonsY</h2>
							<h2>and help us spread the word!</h2>
						</div>
					</div>
				</div>
				<nav className={`Navbar ${this.state.menuOpen ? 'frozen' : ''}`}>
					<a href="/">
						<img
							className="logo"
							src="/img/100ReasonsY_Logo.svg"
							alt="100 Reasons Logo"
						/>
					</a>
					<div className="navigation">
						<ul className="is-hidden-mobile">
							<li>
								<a href="/#new-y">A New Barrie Y</a>
							</li>
							<li>
								<a href="/#reason">What's Your Reason</a>
							</li>
							<li>
								<a href="/#details">Project Details</a>
							</li>
							<li>
								<a href="/donate">Donate</a>
							</li>
						</ul>
						<button
							className={`hamburger hamburger--spring is-hidden-tablet ${
								this.state.menuOpen ? 'is-active' : ''
							}`}
							type="button"
							onClick={this.toggleMenu}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner" />
							</span>
						</button>
					</div>
				</nav>
				<img
					className="swoop"
					src="/img/100ReasonsY_Nav_Swoop.svg"
					alt="Swoop"
				/>
			</React.Fragment>
		);
	}
}

import React from 'react';
import '../styles/ConnectSection.scss';

export default () => {
	return (
		<section className="ConnectSection">
			<div className="sign-up has-text-centered">
				<div>
					<h2 className="reg-heading">Stay in the know:</h2>
					<p>
						We would like to keep you up to date as we move forward with our new
						Barrie Y! Join our mailing list today.
					</p>
					<a
						className="y-button orange"
						href="https://ymcaofsimcoemuskoka.us2.list-manage.com/subscribe?u=78cca50e575807d12e2afb948&id=669b54bb2a"
						target="_blank"
						rel="noopener noreferrer"
					>
						Join the Mailing List
					</a>
				</div>
			</div>
			<div className="details">
				<div className="inner">
					<h2 className="reg-heading">
						The YMCA of Simcoe/Muskoka is a charity commited to building a
						healthy community for all.
					</h2>
					<table>
						<tbody>
							<tr>
								<th>YMCA Mission:</th>
								<td>
									A community-building charity that connects people to each
									other and to experiences designed to build health and
									strength, skills and confidence, and opportunities for
									exploration and growth.
								</td>
							</tr>
							<tr>
								<th>YMCA Vision:</th>
								<td>
									Vibrant and connected communities where everyone belongs.
								</td>
							</tr>
							<tr>
								<th>YMCA Values:</th>
								<td>Kindness, Integrity, Inclusiveness, Respect, Optimism</td>
							</tr>
						</tbody>
					</table>
					<div className="has-text-centered">
						<a href="https://ymcaofsimcoemuskoka.ca/">
							<button className="y-button orange">MORE INFO</button>
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};

import React from 'react';
import '../styles/Footer.scss';

export default () => {
	return (
		<footer className="Footer">
			<div className="logo-bar">
				<img
					className="logo"
					src="/img/100ReasonsY_Logo_Footer.svg"
					alt="100 Reasons Logo"
				/>
				<img
					className="logo"
					src="/img/ImaginCanada_Logo_Footer.png"
					alt="Imagine Canada Logo"
				/>
			</div>
			<div className="legal-bar">
				<small>
					<span>© 2019, YMCA of Simcoe/Muskoka</span>
					<span>Charitable # 1192151109RR0001</span>
				</small>
			</div>
		</footer>
	);
};

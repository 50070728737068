import React from 'react';
import '../styles/Partners.scss';

export default () => {
	return (
		<section className="Partners">
			<h4>Our media partners:</h4>
			<div className="logo-tray">
				<a href="https://rock95.com/" target="_blank" rel="noopener noreferrer">
					<img src="/img/Rock95.jpg" alt="ROCK 95" />
				</a>
				<a
					href="https://1075koolfm.com/"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src="/img/KOOLFM.png" alt="KOOL FM" />
				</a>
			</div>
		</section>
	);
};

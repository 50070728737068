import React from 'react';
import '../styles/BlogMasthead.scss';

export default () => {
	return (
		<section className="section is-medium BlogMasthead">
			<div className="container">
				<h1>PROJECT UPDATES</h1>
			</div>
		</section>
	);
};
